@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

body {
  transition: all 250ms linear 0s;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

a {
  cursor: pointer;
}

ul {
  list-style: none;
}

h1 {
  font-size: 84px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 26px;
  padding: 50px 0 15px;
}

h1,
h2,
h3 {
  text-align: center;
}

.orange-color {
  color: #7342d6;
}

/* navbar */
.navbar__wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  max-width: 1200px;
}

.list__nav--wrapper a {
  text-decoration: none;
  color: #1b1b1b;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.4s ease-in-out 0s;
}

.list__nav--wrapper {
  display: flex;
  align-items: center;
}

.list__nav--wrapper > li {
  margin: 0 10px;
}

/* summary */
#summary {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);
  padding: 0px 8px;
}

.svg__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg__wrapper > div > a {
  display: flex;
}

.svg__wrapper > div > a {
  font-size: 30px;
  color: #7342d6;
}

.summary-position {
  margin-top: -50px;
}

.summary-text {
  font-size: 24px;
  font-weight: 500;
  max-width: 500px;
  margin: 20px auto 0;
}

/* mouse scroll */
.scroll-downs {
  width: 34px;
  height: 55px;
  position: absolute;
  bottom: 10%;
}
.mousey {
  width: 3px;
  padding: 7px 11px;
  height: 35px;
  border: 3px solid #7342d6;
  border-radius: 25px;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #7342d6;
  animation-name: scroll;
  animation-duration: 1.6s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

/* about */
#about {
  background-color: rgb(236, 236, 236);
}

#about,
#skills,
#projects,
#contact {
  padding: 60px 12px;
}

.about__wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(1, 1fr 1fr);
  margin-top: 50px;
}

.about-image img {
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 50%) 0px 10px 20px;
  width: 300px;
  height: 300px;
}

.about-image {
  margin: 0 auto;
}

.about-text {
  font-size: 21px;
  font-weight: 500;
  width: 75%;
}

.about-text > div {
  margin-bottom: 20px;
}

.about-button a {
  padding: 10px 80px;
  font-weight: 500;
  background: #7342d6;
  color: rgb(255, 255, 255);
  border: 2px solid #7342d6;
  text-decoration: none;
  transition: all 200ms ease-in-out 0s;
}

.about-button a:hover {
  background-color: transparent;
  border: 2px solid #7342d6;
  color: #7342d6;
}

/* skill */
.skill__buttons {
  font-size: 40px;
  padding: 10px;
  width: 80px;
  height: 80px;
  border-radius: 10%;
  box-shadow: rgb(0 0 0 / 54%) 0px 3px 10px -3px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skill__buttons svg {
  margin-top: -10px;
}

.button-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.button-wrapper > div {
  margin: 0 auto;
}

.language-buttons,
.technology-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
}

.skill__buttons {
  position: relative;
}

.skills__button--d-block {
  transform: scale(0);
  transition: all 500ms ease 0s;
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  bottom: 5px;
}

.skill__buttons:hover .skills__button--d-block {
  transform: scale(1);
  transition: all 300ms ease 0s;
}

.skill__buttons svg {
  transform: scale(1);
  transition: all 300ms ease 0s;
}

.skill__buttons:hover svg {
  transform: scale(0.9);
  filter: brightness(0.8);
  transition: all 300ms ease 0s;
}

#contact {
  /* padding: 64px 12px 20px; */
  background-color: white;
}

.contact-wrapper {
  display: flex;
  max-width: 600px;
  margin: 50px auto;
}

#contact-form {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  display: grid;
  justify-items: center;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 54%) 0px 3px 10px -3px;
}

.contact-wrapper input,
.contact-wrapper textarea {
  padding: 5px 15px;
  color: rgb(27, 27, 27);
  margin-bottom: 10px;
  align-items: center;
  font-size: 16px;
  width: 100%;
  height: 32px;
  border: 2px solid rgb(236, 236, 236);
  border-radius: 10px;
  outline: none;
  background: transparent;
  transition: all 200ms ease 0s;
  resize: none;
}

.contact-wrapper input {
  height: 46px;
}

.contact-wrapper textarea {
  height: 200px;
}

#contact-button {
  width: 100%;
  color: rgb(255, 255, 255);
  height: 48px;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 600;
  background: #7342d6;
  border: 2px solid #7342d6;
  transition: all 200ms ease-in-out 0s;
}

#contact-button:hover {
  background-color: transparent;
  border: 2px solid #7342d6;
  color: #7342d6;
}

.copyright {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

@media (max-width: 991px) {
  .about__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .button-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .language-buttons,
  .technology-buttons {
    grid-template-columns: repeat(4, 1fr);
  }

  .about-text {
    padding-top: 30px;
    text-align: center;
    font-size: 20px;
    width: 85%;
    margin: 0 auto;
  }

  h1 {
    font-size: 70px;
    line-height: 70px;
  }

  h3 {
    font-size: 22px;
  }

  .button-wrapper {
    flex-direction: column;
  }

  .skills__button--d-block {
    transform: scale(1);
  }

  .skill__buttons svg {
    transform: scale(0.8);
    transition: all 300ms ease 0s;
  }

  .scroll-downs {
    display: none;
  }
}

@media (max-width: 575px) {
  .language-buttons,
  .technology-buttons {
    grid-template-columns: repeat(3, 1fr);
  }

  .navbar__wrapper {
    justify-content: center;
  }
}

.webgl {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

#form-message {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.project {
  margin-bottom: 135px;
}

.project__img {
  width: 100%;
  transition: all 600ms ease;
}

.project__wrapper {
  display: flex;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 20px 80px 0 rgb(0, 0, 0, 0.45);
  overflow: hidden;
}

.project__wrapper:hover .project__description {
  opacity: 1;
  transform: translateY(-50%);
}

.project__wrapper:hover .project__img {
  transform: scale(1.07);
  filter: blur(5px);
}

.project__description {
  position: absolute;
  top: 50%;
  left: 90px;
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 300ms, transform 450ms ease;
  color: white;
  max-width: 550px;
}

.project__wrapper:hover .project__wrapper--bg {
  opacity: 0.7;
  visibility: visible;
}

.project__wrapper--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c1d25;
  opacity: 0;
  transition: all 450ms ease;
  visibility: hidden;
}

.project__description--title {
  font-size: 40px;
}

.project__description--sub-title {
  margin-top: 8px;
}

.project__description--link {
  color: white;
  font-size: 28px;
  margin-right: 16px;
}

.project__description--para {
  margin: 16px 0;
}

#projects {
  background-color: rgb(236, 236, 236);
}

.projects__section--wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.project__description--title {
  text-align: left;
}

.projects__section--title {
  margin-bottom: 50px;
}

.theme__icon {
  font-size: 28px;
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  color: black;
}

.theme__icon:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

.theme__icon:active {
  transform: scale(0.7);
  transition: 0.3s;
}

.toggle__theme,
.toggle__theme #skills,
.toggle__theme #contact {
  background-color: black;
  color: white;
}

.toggle__theme #about,
.toggle__theme #projects {
  background-color: #0b0b0b;
  color: white;
}

.toggle__theme #contact-form {
  background-color: #0b0b0b;
}

.toggle__theme .contact-wrapper input,
.toggle__theme .contact-wrapper textarea,
.toggle__theme .list__nav--wrapper a,
.toggle__theme .theme__icon,
.toggle__theme .threejs__class {
  color: white !important;
}

.list__nav--wrapper a:hover {
  color: #7342d6 !important;
  transition: 0.3s;
}

@media (max-width: 568px) {
  .list__nav--wrapper a {
    font-size: 15px;
  }

  .project__description--para {
    display: none;
  }

  .project__description {
    left: 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .project__description--sub-title {
    margin-bottom: 8px;
  }

  .project__img {
    height: 225px;
  }

  .project__description--title {
    text-align: center;
    padding: 10px;
  }

  .list__nav--wrapper > li {
    margin: 0 5px;
  }
}
